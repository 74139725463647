<script lang="ts" setup>
import { router, useForm } from '@inertiajs/vue3'

import { new_user_registration_path, new_user_session_path } from '../../../routes'

const { flash } = defineProps<{
  title: string
  flash: Record<string, string[]>
}>()

const form = useForm({
  email: '',
  password: '',
})

function signIn() {
  form.transform(data => ({ user: data })).post(new_user_session_path())
}

function goToSignUp() {
  router.visit(new_user_registration_path())
}
</script>

<template>
  <div class="flex justify-center items-center flex-col h-100%">
    <template v-for="(messages, msgType) in flash">
      <TAlert v-for="message in messages" :key="message" :message="message" :theme="msgType" class="mb-4 w-100" />
    </template>

    <TCard class="w-100 mb-4" title="Sign In">
      <TForm @submit="signIn">
        <TFormItem label="Email">
          <TInput v-model="form.email" />
        </TFormItem>
        <TFormItem label="Password" class="mb-4">
          <TInput v-model="form.password" type="password" />
        </TFormItem>

        <div class="flex justify-center flex-col items-center">
          <TButton type="submit" class="mb-4">
            Sign In
          </TButton>

          <div class="mb-4">
            Don't have an account?
          </div>

          <TButton @click="goToSignUp">
            Sign Up
          </TButton>
        </div>
      </TForm>
    </TCard>
  </div>
</template>
