<script lang="ts" setup>
import { Link, router } from '@inertiajs/vue3'
import { ref } from 'vue'

import Logo from '../assets/images/Logo.vue'
import DrawerMenu from '../components/DrawerMenu.vue'
import { destroy_user_session_path } from '../routes'

const { current_user, current_profile_id } = defineProps<{
  // eslint-disable-next-line vue/prop-name-casing
  current_user: { email: string, id: number } | null
  // eslint-disable-next-line vue/prop-name-casing
  current_profile_id: string
}>()

const drawerVisible = ref(false)

function signOut() {
  router.delete(destroy_user_session_path())
}
</script>

<template>
  <TLayout class="h-100vh">
    <THeader>
      <div class="flex h-full items-center px-4">
        <div class="flex flex-col flex-1">
          <Link href="/">
            <Logo class="fill-primary h-6" />
          </Link>
        </div>
        <TButton v-if="current_profile_id" variant="text" @click="drawerVisible = true">
          {{ current_user?.email }}
        </TButton>
      </div>
    </THeader>
    <TContent>
      <slot />
    </TContent>
    <TDrawer v-if="current_profile_id" v-model:visible="drawerVisible" :header="current_user?.email">
      <DrawerMenu :user="current_user" :profile-id="current_profile_id" @close-drawer="drawerVisible = false" />
      <template #footer>
        <TButton style="width: 100%;" variant="text" @click="drawerVisible = false;signOut()">
          <template #icon>
            <TIcon name="delete-1" />
          </template>
          Sign out
        </TButton>
      </template>
    </TDrawer>
    <TFooter class="text-center">
      © {{ new Date().getFullYear() }} Yet Another AI Ltd.
    </TFooter>
  </TLayout>
</template>

<style scoped>
.fill-primary {
  fill: var(--td-brand-color);
}

:deep(.t-default-menu__inner .t-menu) {
  padding: 0;
}
</style>
