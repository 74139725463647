<script lang="ts" setup>
import type { Profile, ProfileForm } from '../../types/user'
import { useForm } from '@inertiajs/vue3'
import { useFileDialog, useObjectUrl } from '@vueuse/core'
import { computed } from 'vue'
import { profile_path } from '../../routes'

const { profile } = defineProps<{
  profile: Profile
}>()

const form = useForm<ProfileForm>({
  display_name: profile.display_name,
  birthday: profile.birthday,
  avatar: null,
})

function saveProfile() {
  const data = form.data()
  if (!data.avatar) {
    delete data.avatar
  }

  form.transform(
    _ => ({ profile: data }),
  ).patch(profile_path(profile.id))
}

const fileDialog = useFileDialog({ accept: 'image/*' })
fileDialog.onChange((files) => {
  form.avatar = files?.[0] ?? null
})
const filePreview = useObjectUrl(computed(() => fileDialog.files.value?.[0] ?? null))
</script>

<template>
  <TCard style="margin: 1rem" title="Profile">
    <TForm @submit="saveProfile">
      <TFormItem label="Avatar">
        <TImage v-if="profile.avatar" :src="profile.avatar" style="width: 110px; height: 110px; object-fit: cover;" />
      </TFormItem>
      <TFormItem label="New avatar">
        <div>
          <TImage v-if="form.avatar" :src="filePreview" style="width: 110px; height: 110px; object-fit: cover;" class="mb-2" />
          <TButton class="mr-2" @click="fileDialog.open">
            <template #icon>
              <TIcon name="upload" />
            </template>
            {{ form.avatar ? 'Change' : 'Select' }}
          </TButton>
          <TButton v-if="form.avatar" @click="fileDialog.reset">
            <template #icon>
              <TIcon name="delete" />
            </template>
            Remove
          </TButton>
        </div>
      </TFormItem>
      <TFormItem label="Display name">
        <TInput v-model="form.display_name" />
      </TFormItem>
      <TFormItem label="Birthday">
        <TDatePicker v-model="form.birthday" />
      </TFormItem>
      <TFormItem>
        <TButton type="submit">
          Save
        </TButton>
      </TFormItem>
    </TForm>
  </TCard>
</template>
