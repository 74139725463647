<script lang="ts" setup>
import { router, useForm } from '@inertiajs/vue3'
import { user_registration_path, user_session_path } from '../../../routes'

defineProps<{
  flash: Record<string, string>
}>()

const form = useForm({
  email: '',
  password: '',
  password_confirmation: '',
})

function goToSignIn() {
  router.visit(user_session_path())
}

function signUp() {
  form.transform(data => ({ user: data })).post(user_registration_path())
}
</script>

<template>
  <div class="flex justify-center items-center flex-col h-100%">
    <template v-for="(messages, msgType) in flash">
      <TAlert v-for="message in messages" :key="message" :message="message" :theme="msgType" class="mb-4 w-100" />
    </template>

    <TCard class="w-100 mb-4" title="Sign up">
      <TForm @submit="signUp">
        <TFormItem label="Email">
          <TInput
            v-model="form.email"
            :status="form.errors.email ? 'error' : 'default'"
            :tips="form.errors.email"
          />
        </TFormItem>
        <TFormItem label="Password">
          <TInput
            v-model="form.password" type="password"
            :status="form.errors.password ? 'error' : 'default'"
            :tips="form.errors.password"
          />
        </TFormItem>
        <TFormItem label="Password">
          <TInput
            v-model="form.password_confirmation"
            type="password"
            :status="form.errors.password_confirmation ? 'error' : 'default'"
            :tips="form.errors.password_confirmation"
          />
        </TFormItem>

        <div class="flex justify-center flex-col items-center">
          <TButton type="submit" class="mb-4">
            Sign up
          </TButton>

          <div class="mb-4">
            Already have an account?
          </div>

          <TButton @click="goToSignIn">
            Sign in
          </TButton>
        </div>
      </TForm>
    </TCard>
  </div>
</template>
