<script lang="ts" setup>
import { router } from '@inertiajs/vue3'
import { ref } from 'vue'
import { edit_profile_path } from '../routes'

const { profileId } = defineProps<{
  user: { email: string, id: number } | null
  profileId: string
}>()

const emit = defineEmits<{
  (e: 'closeDrawer'): void
}>()

const menuItems = [
  {
    icon: 'home',
    text: 'Home',
    href: '/',
  },
  {
    icon: 'user',
    text: 'Profile',
    href: edit_profile_path(profileId),
  },
]

const route = ref(globalThis.location.pathname)

router.on('start', (event) => {
  route.value = event.detail.visit.url.pathname
})

function onMenuItemClick(href: string) {
  if (href === route.value) {
    emit('closeDrawer')
    return
  }

  router.visit(href)
}
</script>

<template>
  <TMenu style="width: 100%" :value="route">
    <TMenuItem
      v-for="menuItem in menuItems"
      :key="menuItem.href"
      :value="menuItem.href"
      @click="onMenuItemClick(menuItem.href)"
    >
      <template #icon>
        <TIcon :name="menuItem.icon" />
      </template>
      {{ menuItem.text }}
    </TMenuItem>
  </TMenu>
</template>
