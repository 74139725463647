import type { DefineComponent } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'

import TDesign from 'tdesign-vue-next'
import { createApp, h } from 'vue'
import Layout from '../layouts/Default.vue'

import 'tdesign-vue-next/es/style/index.css'

import 'virtual:uno.css'

const pages = import.meta.glob<DefineComponent>('../pages/**/*.vue', { eager: true })

createInertiaApp({
  // Set default page title
  // see https://inertia-rails.netlify.app/guide/title-and-meta
  //
  // title: title => title ? `${title} - App` : 'App',

  // Disable progress bar
  //
  // see https://inertia-rails.netlify.app/guide/progress-indicators
  // progress: false,

  resolve: (name) => {
    const page = pages[`../pages/${name}.vue`]

    page.default.layout ||= Layout

    return page

    // To use a default layout, import the Layout component
    // and use the following lines.
    // see https://inertia-rails.netlify.app/guide/pages#default-layouts
    //
    // const page = pages[`../pages/${name}.vue`]
    // page.default.layout = page.default.layout || Layout
    // return page
  },

  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(TDesign)
      .mount(el)
  },
})
